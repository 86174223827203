html {
  height: 100%;
  box-sizing: border-box;
}

body {
  background-color: #f5f5f5;
  margin: 0;
  padding: 0;
  position: relative;
  margin: 0;
  min-height: 100%;
}

p, h1, h2, h3, h4, h5, svg {
  color: #2c3739;
}

body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

main {
  min-height: 100%;
  min-width: 100%;
}

#root {
  height: 100%;
  width: 100%;  
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.font-display {
  font-family: NunitoSans-ExtraLight;
  font-size: 50px;
  line-height: 64px; }

.font-title {
  font-family: NunitoSans-ExtraLight;
  font-size: 34px;
  line-height: 48px; }

.font-subtitle {
  font-family: NunitoSans;
  font-size: 24px;
  line-height: 32px; }

.font-text-large {
  font-family: NunitoSans;
  font-size: 16px;
  line-height: 24px; }

.font-text-large-bold {
  font-family: NunitoSans-Bold;
  font-size: 16px;
  line-height: 24px; }

.font-text {
  font-family: NunitoSans;
  font-size: 14px;
  line-height: 24px; }

.font-text-bold {
  font-family: NunitoSans-Bold;
  font-size: 14px;
  line-height: 24px; }

.font-text-uppercase {
  font-family: NunitoSans-Bold;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase; }

.font-text-small {
  font-family: NunitoSans;
  font-size: 12px;
  line-height: 16px; }

.font-text-small-bold {
  font-family: NunitoSans-Bold;
  font-size: 12px;
  line-height: 16px; }

.font-text-smaller {
  font-family: NunitoSans-Bold;
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase; }

$color-primary: #0c9abe;
$color-primaryLight: #ceeaf2;
$color-primaryDark: #097b98;

$color-secondary: #ff9a3f;
$color-secondaryLight: #FD8B64;
$color-secondaryDark: #F45E29;

$color-neutralDarker: #1d2426;
$color-neutrayDark: #2c3739;
$color-neutral: #4a5c60;
$color-neutralLight: #dadedf;
$color-neutralLighter: #eceeee;
$color-neutralLightest: #fbfbfb;
$color-black: #000000;
$color-white: #fff;
$color-success: #00b28e;
$color-warning: #ea9b3e;
$color-danger: #c64840;
$color-danger-dark-5: #bb443c;
$color-danger-dark-20: #9e3933;
$color-danger-dark-40: #762b26;
$color-transparent: transparent;




$fastAnimation: 0.5s;

.font-display {
    font-family: NunitoSans-ExtraLight;
    font-size: 50px;
    line-height: 64px;
}

.font-title {
    font-family: NunitoSans-ExtraLight;
    font-size: 34px;
    line-height: 48px;
}

.font-subtitle {
    font-family: NunitoSans;
    font-size: 24px;
    line-height: 32px;
}

.font-text-large {
    font-family: NunitoSans;
    font-size: 16px;
    line-height: 24px;
}

.font-text-large-bold {
    font-family: NunitoSans-Bold;
    font-size: 16px;
    line-height: 24px;
}

.font-text {
    font-family: NunitoSans;
    font-size: 14px;
    line-height: 24px;
}

.font-text-bold {
    font-family: NunitoSans-Bold;
    font-size: 14px;
    line-height: 24px;
}

.font-text-uppercase {
    font-family: NunitoSans-Bold;
    font-size: 14px;
    line-height: 24px;
    text-transform: uppercase;
}

.font-text-small {
    font-family: NunitoSans;
    font-size: 12px;
    line-height: 16px;
}

.font-text-small-bold {
    font-family: NunitoSans-Bold;
    font-size: 12px;
    line-height: 16px;
}

.font-text-smaller {
font-family: NunitoSans-Bold;
font-size: 10px;
line-height: 16px;
text-transform: uppercase;
}



.app-button-float:hover {
    color: white;
}

.main {
    flex: auto;
    background-color: white;
    height: 100%;
    position: relative;
    width: 100%;

}

.main-container {
    width: 100%;
    min-height: 100%;
    padding-bottom: 70px;
    padding-top: 80px;
}

.main-content {
    /* max-width: 950px; */
    height: 100%;;
    margin: auto;
}

.app-button-float{
	position:fixed;
	width:70px;
	height:70px;
	bottom:40px;
	right:40px;
	background-color:#0C9;
	color:#FFF;
	border-radius:50px;
	text-align:center;
    box-shadow: 2px 2px 3px #999;
    overflow: hidden;
    line-height: 70px;
    display: block;
}

.app-icon {
    /* font-size: 2rem; */
    width: 40px;
    height: 40px;
}

@media (min-width: 560px ) {
    .app-button-float{
        display: none;
    } 
}

.font-display {
  font-family: NunitoSans-ExtraLight;
  font-size: 50px;
  line-height: 64px; }

.font-title {
  font-family: NunitoSans-ExtraLight;
  font-size: 34px;
  line-height: 48px; }

.font-subtitle {
  font-family: NunitoSans;
  font-size: 24px;
  line-height: 32px; }

.font-text-large {
  font-family: NunitoSans;
  font-size: 16px;
  line-height: 24px; }

.font-text-large-bold {
  font-family: NunitoSans-Bold;
  font-size: 16px;
  line-height: 24px; }

.font-text {
  font-family: NunitoSans;
  font-size: 14px;
  line-height: 24px; }

.font-text-bold {
  font-family: NunitoSans-Bold;
  font-size: 14px;
  line-height: 24px; }

.font-text-uppercase {
  font-family: NunitoSans-Bold;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase; }

.font-text-small {
  font-family: NunitoSans;
  font-size: 12px;
  line-height: 16px; }

.font-text-small-bold {
  font-family: NunitoSans-Bold;
  font-size: 12px;
  line-height: 16px; }

.font-text-smaller {
  font-family: NunitoSans-Bold;
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase; }

.nabbar-cust {
  display: flex;
  justify-content: space-between;
  font-family: Montserrat, sans-serif;
  margin-bottom: 0;
  background-color: transparent;
  border: 0;
  margin: auto;
  font-size: 22px;
  letter-spacing: 4px;
  opacity: 0.9; }

.navbar-collapse {
  display: flex;
  justify-content: center;
  width: 100%; }

.nav-link {
  text-align: center; }

.nav-link__active {
  color: #ff9a3f; }

.header-nav__logo {
  width: 200px; }

.header-nav__menu__link {
  -webkit-transition: .3s;
  transition: .3s; }

.header-nav__menu__link:hover {
  color: #ff9a3f !important; }

.header-nav__menu__link:hover {
  color: #ff9a3f; }

.header__nav-item {
  width: 100%; }

.header__nav-items {
  width: 100%;
  font-size: 1rem; }

.current {
  color: red; }

.menu-mob {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: #f8f9fa; }

.responsive-menu__icon {
  z-index: 1; }

.responsive__tag {
  position: relative; }

@media (min-width: 992px) {
  .menu-mob {
    position: relative;
    background: transparent; }
  .navbar-collapse {
    justify-content: flex-end; }
  .header__navbar {
    padding-right: 3rem;
    padding-left: 3rem; }
  .header__nav-items {
    width: 100%;
    font-size: 1rem; }
  .header__nav-item, .header__nav-items {
    width: auto;
    font-size: 1.5rem; }
  .header__nav-item:hover {
    color: #0c9abe; }
  .responsive__tag {
    position: absolute; } }

.font-display {
  font-family: NunitoSans-ExtraLight;
  font-size: 50px;
  line-height: 64px; }

.font-title {
  font-family: NunitoSans-ExtraLight;
  font-size: 34px;
  line-height: 48px; }

.font-subtitle {
  font-family: NunitoSans;
  font-size: 24px;
  line-height: 32px; }

.font-text-large {
  font-family: NunitoSans;
  font-size: 16px;
  line-height: 24px; }

.font-text-large-bold {
  font-family: NunitoSans-Bold;
  font-size: 16px;
  line-height: 24px; }

.font-text {
  font-family: NunitoSans;
  font-size: 14px;
  line-height: 24px; }

.font-text-bold {
  font-family: NunitoSans-Bold;
  font-size: 14px;
  line-height: 24px; }

.font-text-uppercase {
  font-family: NunitoSans-Bold;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase; }

.font-text-small {
  font-family: NunitoSans;
  font-size: 12px;
  line-height: 16px; }

.font-text-small-bold {
  font-family: NunitoSans-Bold;
  font-size: 12px;
  line-height: 16px; }

.font-text-smaller {
  font-family: NunitoSans-Bold;
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase; }

.header {
  width: 100%;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 3px 5px -5px #333; }

.font-display {
  font-family: NunitoSans-ExtraLight;
  font-size: 50px;
  line-height: 64px; }

.font-title {
  font-family: NunitoSans-ExtraLight;
  font-size: 34px;
  line-height: 48px; }

.font-subtitle {
  font-family: NunitoSans;
  font-size: 24px;
  line-height: 32px; }

.font-text-large {
  font-family: NunitoSans;
  font-size: 16px;
  line-height: 24px; }

.font-text-large-bold {
  font-family: NunitoSans-Bold;
  font-size: 16px;
  line-height: 24px; }

.font-text {
  font-family: NunitoSans;
  font-size: 14px;
  line-height: 24px; }

.font-text-bold {
  font-family: NunitoSans-Bold;
  font-size: 14px;
  line-height: 24px; }

.font-text-uppercase {
  font-family: NunitoSans-Bold;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase; }

.font-text-small {
  font-family: NunitoSans;
  font-size: 12px;
  line-height: 16px; }

.font-text-small-bold {
  font-family: NunitoSans-Bold;
  font-size: 12px;
  line-height: 16px; }

.font-text-smaller {
  font-family: NunitoSans-Bold;
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase; }

.page-footer {
  width: 100%;
  color: #fff;
  background: #2f3036;
  padding: 1rem;
  height: 70px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0; }

.font-display {
  font-family: NunitoSans-ExtraLight;
  font-size: 50px;
  line-height: 64px; }

.font-title {
  font-family: NunitoSans-ExtraLight;
  font-size: 34px;
  line-height: 48px; }

.font-subtitle {
  font-family: NunitoSans;
  font-size: 24px;
  line-height: 32px; }

.font-text-large {
  font-family: NunitoSans;
  font-size: 16px;
  line-height: 24px; }

.font-text-large-bold {
  font-family: NunitoSans-Bold;
  font-size: 16px;
  line-height: 24px; }

.font-text {
  font-family: NunitoSans;
  font-size: 14px;
  line-height: 24px; }

.font-text-bold {
  font-family: NunitoSans-Bold;
  font-size: 14px;
  line-height: 24px; }

.font-text-uppercase {
  font-family: NunitoSans-Bold;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase; }

.font-text-small {
  font-family: NunitoSans;
  font-size: 12px;
  line-height: 16px; }

.font-text-small-bold {
  font-family: NunitoSans-Bold;
  font-size: 12px;
  line-height: 16px; }

.font-text-smaller {
  font-family: NunitoSans-Bold;
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase; }

.footer-copyright {
  justify-content: center;
  align-items: center; }

.footer-copyright__container {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  padding-bottom: .5rem;
  margin-top: .5rem;
  cursor: pointer;
  text-align: center; }

.footer-copyright__container:hover {
  color: #0c9abe; }

/* Larger than mobile */
@media (min-width: 550px) {
  .footer-copyright {
    display: flex; } }

/* Larger than tablet */
@media (min-width: 750px) {
  .footer-copyright {
    display: flex;
    padding-top: 99%; } }

@media (min-width: 980px) {
  .footer-copyright {
    display: flex;
    padding-top: 1%; } }

/* Larger than Desktop HD */
@media (min-width: 1200px) {
  .footer-copyright {
    display: flex;
    padding-top: 1%; } }

.carousel, .carousel-img1, .carousel-img2, .carousel-img3, .carousel-img4 {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 3px;
  width: 100%;
  height: 150px; }

.carousel-img1 {
  background-image: url(/static/media/cidade_ilum.554365d6.jpg); }

.carousel-img2 {
  background-image: url(/static/media/imagem_slide2.f91e5247.jpg); }

.carousel-img3 {
  background-image: url(/static/media/imagem_slide3.56cb180e.jpg); }

.carousel-img4 {
  background-image: url(/static/media/imagem_slide4.401b73e8.jpg); }

.carousel__text {
  color: white;
  font-weight: bold;
  -webkit-text-stroke-color: #ff9a3f;
  -webkit-text-stroke-width: .1px;
  font-size: 2em; }

@media (min-width: 980px) {
  .carousel-img {
    height: 100px; }
  .carousel, .carousel-img1, .carousel-img2, .carousel-img3, .carousel-img4 {
    height: 300px; } }

.font-display {
  font-family: NunitoSans-ExtraLight;
  font-size: 50px;
  line-height: 64px; }

.font-title {
  font-family: NunitoSans-ExtraLight;
  font-size: 34px;
  line-height: 48px; }

.font-subtitle {
  font-family: NunitoSans;
  font-size: 24px;
  line-height: 32px; }

.font-text-large {
  font-family: NunitoSans;
  font-size: 16px;
  line-height: 24px; }

.font-text-large-bold {
  font-family: NunitoSans-Bold;
  font-size: 16px;
  line-height: 24px; }

.font-text {
  font-family: NunitoSans;
  font-size: 14px;
  line-height: 24px; }

.font-text-bold {
  font-family: NunitoSans-Bold;
  font-size: 14px;
  line-height: 24px; }

.font-text-uppercase {
  font-family: NunitoSans-Bold;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase; }

.font-text-small {
  font-family: NunitoSans;
  font-size: 12px;
  line-height: 16px; }

.font-text-small-bold {
  font-family: NunitoSans-Bold;
  font-size: 12px;
  line-height: 16px; }

.font-text-smaller {
  font-family: NunitoSans-Bold;
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase; }

.carousel--container {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #000000; }

.home-icon {
  font-size: 4rem; }

.home-solcucoes__icon {
  font-size: 3rem;
  color: #0c9abe; }

.home-icon__whatsapp {
  color: #34af23; }

.home-icon__primary {
  color: #ff9a3f; }

.home__link {
  color: #0c9abe; }

.home__link:hover {
  color: #ff9a3f; }

.home__card {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  text-align: justify; }

.navbar-brand a {
  color: #0c9abe;
  text-decoration: none; }

.navbar-brand a:hover {
  color: #ff9a3f; }

.font-display {
  font-family: NunitoSans-ExtraLight;
  font-size: 50px;
  line-height: 64px; }

.font-title {
  font-family: NunitoSans-ExtraLight;
  font-size: 34px;
  line-height: 48px; }

.font-subtitle {
  font-family: NunitoSans;
  font-size: 24px;
  line-height: 32px; }

.font-text-large {
  font-family: NunitoSans;
  font-size: 16px;
  line-height: 24px; }

.font-text-large-bold {
  font-family: NunitoSans-Bold;
  font-size: 16px;
  line-height: 24px; }

.font-text {
  font-family: NunitoSans;
  font-size: 14px;
  line-height: 24px; }

.font-text-bold {
  font-family: NunitoSans-Bold;
  font-size: 14px;
  line-height: 24px; }

.font-text-uppercase {
  font-family: NunitoSans-Bold;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase; }

.font-text-small {
  font-family: NunitoSans;
  font-size: 12px;
  line-height: 16px; }

.font-text-small-bold {
  font-family: NunitoSans-Bold;
  font-size: 12px;
  line-height: 16px; }

.font-text-smaller {
  font-family: NunitoSans-Bold;
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase; }

.title {
  display: table;
  width: 100%; }

.title-row {
  display: table-row; }

.bar-container {
  display: table-cell;
  position: relative;
  width: 50%; }

.bar {
  position: absolute;
  width: 100%;
  top: 55%;
  border-bottom: 1px solid #ecedf3; }

.text {
  display: table-cell;
  padding: 0 5px;
  font-size: 2rem;
  color: #4a5c60; }

.font-display {
  font-family: NunitoSans-ExtraLight;
  font-size: 50px;
  line-height: 64px; }

.font-title {
  font-family: NunitoSans-ExtraLight;
  font-size: 34px;
  line-height: 48px; }

.font-subtitle {
  font-family: NunitoSans;
  font-size: 24px;
  line-height: 32px; }

.font-text-large {
  font-family: NunitoSans;
  font-size: 16px;
  line-height: 24px; }

.font-text-large-bold {
  font-family: NunitoSans-Bold;
  font-size: 16px;
  line-height: 24px; }

.font-text {
  font-family: NunitoSans;
  font-size: 14px;
  line-height: 24px; }

.font-text-bold {
  font-family: NunitoSans-Bold;
  font-size: 14px;
  line-height: 24px; }

.font-text-uppercase {
  font-family: NunitoSans-Bold;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase; }

.font-text-small {
  font-family: NunitoSans;
  font-size: 12px;
  line-height: 16px; }

.font-text-small-bold {
  font-family: NunitoSans-Bold;
  font-size: 12px;
  line-height: 16px; }

.font-text-smaller {
  font-family: NunitoSans-Bold;
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase; }

.content-main {
  width: 100%;
  height: 100%;
  padding: 2rem 2rem 0 2rem; }

.content-main__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0 8px 15px 0 rgba(154, 154, 154, 0.5);
  background-color: #fff;
  align-items: stretch;
  margin: auto;
  padding: .5rem; }

.empresa-container {
  padding: 0 1rem; }

.home-paragrafo {
  text-align: justify; }

.empresa-icon__primary {
  float: left;
  margin: 0 1rem 0 0; }

.empresa-container {
  margin: auto; }

.empresa__title {
  font-size: 2rem; }

@media (min-width: 980px) {
  .empresa-container {
    padding: 0 2rem;
    text-align: center; } }

.servico-container {
  margin: auto; }

.font-display {
  font-family: NunitoSans-ExtraLight;
  font-size: 50px;
  line-height: 64px; }

.font-title {
  font-family: NunitoSans-ExtraLight;
  font-size: 34px;
  line-height: 48px; }

.font-subtitle {
  font-family: NunitoSans;
  font-size: 24px;
  line-height: 32px; }

.font-text-large {
  font-family: NunitoSans;
  font-size: 16px;
  line-height: 24px; }

.font-text-large-bold {
  font-family: NunitoSans-Bold;
  font-size: 16px;
  line-height: 24px; }

.font-text {
  font-family: NunitoSans;
  font-size: 14px;
  line-height: 24px; }

.font-text-bold {
  font-family: NunitoSans-Bold;
  font-size: 14px;
  line-height: 24px; }

.font-text-uppercase {
  font-family: NunitoSans-Bold;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase; }

.font-text-small {
  font-family: NunitoSans;
  font-size: 12px;
  line-height: 16px; }

.font-text-small-bold {
  font-family: NunitoSans-Bold;
  font-size: 12px;
  line-height: 16px; }

.font-text-smaller {
  font-family: NunitoSans-Bold;
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase; }

.thf-icon-camera {
  font-size: 36px;
  color: #ff9a3f; }

.parceiros-img {
  max-width: 120px; }

@media (min-width: 770px) {
  .parceiros-img {
    max-width: 150px; } }

.contatos-form {
  padding: 1rem; }

.contatos__map {
  width: 100%;
  height: 450px; }

@media (min-width: 980px) {
  .contatos-form {
    padding: 2rem 10rem; } }

.gallery {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-width: 33%;
  -moz-column-width: 33%;
  column-width: 33%; }

.buttons__container {
  padding: 1rem; }

.button__filter {
  color: red !important; }

.gallery .pics {
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease; }

.gallery .animation {
  -webkit-transform: scale(1);
  transform: scale(1); }

@media (max-width: 450px) {
  .gallery {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
    -webkit-column-width: 100%;
    -moz-column-width: 100%;
    column-width: 100%; }
  .buttons__container {
    padding: .2rem; } }

@media (max-width: 400px) {
  .btn.filter {
    padding-left: 1.1rem;
    padding-right: 1.1rem; } }


// @import "src/assets/styles/index.scss";
.carousel {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-radius: 3px;
    width: 100%;
    height: 150px;
}

.carousel-img1 {
    @extend .carousel;
    background-image: url('../../assets/images/carousel/cidade_ilum.jpg');
}

.carousel-img2 {
    @extend .carousel;
    background-image: url('../../assets/images/carousel/imagem_slide2.jpg');
}

.carousel-img3 {
    @extend .carousel;
    background-image: url('../../assets/images/carousel/imagem_slide3.jpg');
}

.carousel-img4 {
    @extend .carousel;
    background-image: url('../../assets/images/carousel/imagem_slide4.jpg');
}

.carousel__text {
    color: white;
    font-weight: bold;
    -webkit-text-stroke-color: #ff9a3f;
    -webkit-text-stroke-width: .1px;
    font-size: 2em;
}

@media (min-width: 980px) {
    .carousel-img {
        height: 100px;
    }

    .carousel {
        height: 300px;
    }

}
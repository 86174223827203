@import "src/app/assets/styles/index.scss";

.carousel--container {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: $color-black;
}

.home-icon {
    font-size: 4rem;
}

.home-solcucoes__icon {
    font-size: 3rem;
    color: $color-primary
}

// .home-solcucoes__icon:hover {
//     color: $color-secondary;;
// }

.home-icon__whatsapp {
    color: #34af23;    
}

.home-icon__primary {
    color: $color-secondary;
}

.home__link {
    color: $color-primary;
}

.home__link:hover {
    color: $color-secondary;
}

.home__card {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;    
    text-align: justify;
}

.navbar-brand a{
    color: $color-primary;
    text-decoration: none; 
}

.navbar-brand a:hover{
    color: $color-secondary;
}


@import "src/app/assets/styles/index.scss";

.nabbar-cust {
    display: flex;
    justify-content: space-between;
    font-family: Montserrat, sans-serif;
    margin-bottom: 0;
    background-color: $color-transparent;
    border: 0;
    // max-width: 1000px;
    margin: auto;
    font-size: 22px;
    letter-spacing: 4px;
    opacity: 0.9;    
}

.navbar-collapse {
    display: flex;
    justify-content: center;
    width: 100%;
}

.nav-link {
    text-align: center;
}

.nav-link__active {
    color: $color-secondary;
}

.header-nav__logo {
    width: 200px;
}

.header-nav__menu__item {
}

.header-nav__menu__link {
    transition: .3s;
}

.header-nav__menu__link:hover {
    color: $color-secondary !important;
}

.header-nav__menu__link:hover {
    color: $color-secondary
}

.header__nav-item {
    width: 100%;
}

.header__nav-items {
    width: 100%;
    font-size: 1rem;
}

.current {
    color: red;
}

.menu-mob {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background: #f8f9fa;
}

.responsive-menu__icon {
    z-index: 1;
}

.responsive__tag {
    position: relative;
}

@media (min-width: 992px) {
    .menu-mob {
        position: relative;
        background: transparent;
    }

    .navbar-collapse {
        justify-content: flex-end;
    }

    .header__navbar {
        padding-right: 3rem;
        padding-left: 3rem;
    }

    .header__nav-items {
        width: 100%;
        font-size: 1rem;
    }

    .header__nav-item, .header__nav-items {
        width: auto;
        font-size: 1.5rem;        
    }
    
    .header__nav-item:hover {
        color: $color-primary; 
    }

    .responsive__tag {
        position: absolute;
    }
   
}
@import "src/app/assets/styles/index.scss";

.thf-icon-camera {
    font-size: 36px;
    color: $color-secondary;
}

.parceiros-img {
    max-width: 120px;
}

@media(min-width: 770px) {
    .parceiros-img {
        max-width: 150px;
    }    
}
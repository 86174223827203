
.contatos-form {
    padding: 1rem;
}

.contatos__map {
    width: 100%;
    height: 450px;
}

@media (min-width: 980px) {
    .contatos-form {
        padding: 2rem 10rem;
    }    
}

@import "./app/assets/styles/index.scss";

.app-button-float:hover {
    color: white;
}

.main {
    flex: auto;
    background-color: white;
    height: 100%;
    position: relative;
    width: 100%;

}

.main-container {
    width: 100%;
    min-height: 100%;
    padding-bottom: 70px;
    padding-top: 80px;
}

.main-content {
    /* max-width: 950px; */
    height: 100%;;
    margin: auto;
}

.app-button-float{
	position:fixed;
	width:70px;
	height:70px;
	bottom:40px;
	right:40px;
	background-color:#0C9;
	color:#FFF;
	border-radius:50px;
	text-align:center;
    box-shadow: 2px 2px 3px #999;
    overflow: hidden;
    line-height: 70px;
    display: block;
}

.app-icon {
    /* font-size: 2rem; */
    width: 40px;
    height: 40px;
}

@media (min-width: 560px ) {
    .app-button-float{
        display: none;
    } 
}

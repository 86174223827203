@import "src/app/assets/styles/index.scss";

.page-footer {
    width: 100%;
    color: #fff;
    background: rgba(47, 48, 54, 1);
    padding: 1rem;
    height: 70px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
}
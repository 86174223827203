@import "src/app/assets/styles/index.scss";

.content-main {
    width: 100%;
    height: 100%;
    padding: 2rem 2rem 0 2rem;
}

.content-main__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    box-shadow: 0 8px 15px 0 rgba(154, 154, 154, 0.5);
    background-color: $color-white;
    align-items: stretch;
    margin: auto;
    padding: .5rem;    
}
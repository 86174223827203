.empresa-container {
    padding: 0 1rem;
}

.home-paragrafo {
    text-align: justify;
}

.empresa-icon__primary {
    float: left;
    margin: 0 1rem 0 0;
}

.empresa-container {
    margin: auto;
}

.empresa__title {
    font-size: 2rem;
}

@media (min-width: 980px) {
    .empresa-container {
        padding: 0 2rem;
        text-align: center;
    }    
}

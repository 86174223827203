$color-primary: #0c9abe;
$color-primaryLight: #ceeaf2;
$color-primaryDark: #097b98;

$color-secondary: #ff9a3f;
$color-secondaryLight: #FD8B64;
$color-secondaryDark: #F45E29;

$color-neutralDarker: #1d2426;
$color-neutrayDark: #2c3739;
$color-neutral: #4a5c60;
$color-neutralLight: #dadedf;
$color-neutralLighter: #eceeee;
$color-neutralLightest: #fbfbfb;
$color-black: #000000;
$color-white: #fff;
$color-success: #00b28e;
$color-warning: #ea9b3e;
$color-danger: #c64840;
$color-danger-dark-5: #bb443c;
$color-danger-dark-20: #9e3933;
$color-danger-dark-40: #762b26;
$color-transparent: transparent;

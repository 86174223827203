@import "src/app/assets/styles/index.scss";

.footer-copyright {
    justify-content: center;
    align-items: center;
}

.footer-copyright__container {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: .5rem;
    margin-top: .5rem;
    cursor: pointer;
    text-align: center;
}

.footer-copyright__container:hover {
    color: $color-primary;
}

/* Larger than mobile */
@media (min-width: 550px) {
    .footer-copyright {
        display: flex;
    }

}

/* Larger than tablet */
@media (min-width: 750px) {
    .footer-copyright {
        display: flex;
        padding-top: 99%;
    }
}

@media (min-width: 980px) {
    .footer-copyright {
        display: flex;
        padding-top: 1%;
    }
}

/* Larger than Desktop HD */
@media (min-width: 1200px) {
    .footer-copyright {
        display: flex;
        padding-top: 1%;
    }
}
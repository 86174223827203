$fastAnimation: 0.5s;

.font-display {
    font-family: NunitoSans-ExtraLight;
    font-size: 50px;
    line-height: 64px;
}

.font-title {
    font-family: NunitoSans-ExtraLight;
    font-size: 34px;
    line-height: 48px;
}

.font-subtitle {
    font-family: NunitoSans;
    font-size: 24px;
    line-height: 32px;
}

.font-text-large {
    font-family: NunitoSans;
    font-size: 16px;
    line-height: 24px;
}

.font-text-large-bold {
    font-family: NunitoSans-Bold;
    font-size: 16px;
    line-height: 24px;
}

.font-text {
    font-family: NunitoSans;
    font-size: 14px;
    line-height: 24px;
}

.font-text-bold {
    font-family: NunitoSans-Bold;
    font-size: 14px;
    line-height: 24px;
}

.font-text-uppercase {
    font-family: NunitoSans-Bold;
    font-size: 14px;
    line-height: 24px;
    text-transform: uppercase;
}

.font-text-small {
    font-family: NunitoSans;
    font-size: 12px;
    line-height: 16px;
}

.font-text-small-bold {
    font-family: NunitoSans-Bold;
    font-size: 12px;
    line-height: 16px;
}

.font-text-smaller {
font-family: NunitoSans-Bold;
font-size: 10px;
line-height: 16px;
text-transform: uppercase;
}

@import "src/app/assets/styles/index.scss";

.title{
    display: table;
    width: 100%;
}

.title-row{
    display: table-row;
}
.bar-container {
    display: table-cell;
    position: relative;
    width: 50%;
}
.bar {
    position: absolute;
    width: 100%;
    top: 55%;
    border-bottom: 1px solid #ecedf3
}
.text {
    display: table-cell;
    padding: 0 5px;
    font-size: 2rem;
    color: $color-neutral;
}